<template>
  <div>
    <v-row class="pt-12" align="center" justify="center">
      <v-col cols="6">
        <h4 class="text-center text-h4">Tell us about yourself</h4>
      </v-col>
    </v-row>

    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="4" class="d-flex justify-center align-center">
        <p>Existing customer account to reference?</p>
      </v-col>
    </v-row>
    <v-row class="pt-0 pb-4" align="center" justify="center">
      <v-col cols="4" class="d-flex justify-center align-center">
        <v-radio-group
          v-model="currentItem.existingAccount"
          @change="onChange"
          row
        >
          <v-radio label="Yes" value="Yes"></v-radio>
          <v-radio label="No" value="No"></v-radio>
          <v-radio label="Unsure" value="Unsure"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row
      v-if="currentItem.existingAccount === 'Yes'"
      class="pt-0"
      align="center"
      justify="center"
    >
      <v-col cols="4" class="d-flex">
        <v-text-field
          v-model="currentItem.accountCode"
          @change="onChange"
          label="Input 5 Character Account Code"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AboutClientContact',
  props: {
    order: Object
  },
  data: function () {
    return {
      currentSection: 'start',
      project: {
        client: {}
      },
      currentItem: {
        fullName: this.order.client.fullName,
        title: this.order.client.title,
        organization: this.order.client.organization,
        existingAccount: this.order.client.existingAccount,
        accountCode: this.order.client.accountCode,
        phone: this.order.client.phone,
        email: this.order.client.email,
        contactCall: this.order.client.contactCall,
        contactText: this.order.client.contactText,
        contactEmail: this.order.client.contactEmail,
        contactMethod: this.order.client.contactMethod
          ? [...this.order.client.contactMethod]
          : []
      }
    }
  },
  methods: {
    onSave () {
      this.project.client = { ...this.currentItem }

      this.$emit('onChange', {
        project: this.project
      })
      this.currentSection = 'add'
      this.$emit('onChangeSection', {
        section: 'finish'
      })
    },
    onChange () {
      this.project.client = { ...this.currentItem }
      this.$emit('onChange', {
        project: this.project
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
