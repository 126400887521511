<template>
  <div>
    <v-row class="pt-12" align="center" justify="center">
      <v-col cols="6">
        <h4 class="text-center text-h4">Tell us about yourself</h4>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex">
        <v-text-field
          v-model="currentItem.fullName"
          @input="onChange($event, 'fullName')"
          @blur="$v.currentItem.fullName.$touch()"
          label="Full Name"
          :error-messages="fullNameErrors"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex">
        <v-text-field
          v-model="currentItem.title"
          @input="onChange($event, 'title')"
          @blur="$v.currentItem.title.$touch()"
          label="Title"
          :error-messages="titleErrors"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex">
        <v-text-field
          v-model="currentItem.organization"
          @input="onChange($event, 'organization')"
          @blur="$v.currentItem.organization.$touch()"
          label="Company / School"
          :error-messages="organizationErrors"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex">
        <v-text-field
          v-model="currentItem.phone"
          @input="formatPhone"
          @blur="$v.currentItem.phone.$touch()"
          label="Phone"
          :error-messages="phoneErrors"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col cols="6" class="d-flex">
        <v-text-field
          v-model="currentItem.email"
          @input="onChange($event, 'email')"
          @blur="$v.currentItem.email.$touch()"
          label="Email"
          :error-messages="emailErrors"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pt-4" align="center" justify="center">
      <v-col
        cols="4"
        class="pb-0 d-flex flex-column justify-center align-center"
      >
        <p class="font-weight-bold">Preferred Contact Method</p>
        <p
          class="error--text"
          v-for="(error, i) in contactMethodErrors"
          v-bind:key="i"
        >
          {{ error }}
        </p>
      </v-col>
    </v-row>
    <v-row class="pt-0 pb-4" align="center" justify="center">
      <v-col cols="4" class="d-flex justify-center align-center">
        <v-checkbox
          class=""
          v-model="currentItem.contactCall"
          label="Call"
          @blur="$v.currentItem.contactCall.$touch()"
          @change="onChange($event, 'contactCall')"
        ></v-checkbox>
        <v-checkbox
          class="ml-6 mr-6"
          v-model="currentItem.contactText"
          label="Text"
          @blur="$v.currentItem.contactText.$touch()"
          @change="onChange($event, 'contactText')"
        ></v-checkbox>
        <v-checkbox
          v-model="currentItem.contactEmail"
          label="Email"
          @blur="$v.currentItem.contactEmail.$touch()"
          @change="onChange($event, 'contactEmail')"
        ></v-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'AboutClient',
  props: {
    items: Array,
    order: Object
  },
  data: function () {
    return {
      currentSection: 'start',
      project: {
        client: {}
      },
      currentItem: {
        fullName: this.order.client.fullName,
        title: this.order.client.title,
        organization: this.order.client.organization,
        existingAccount: this.order.client.existingAccount,
        accountCode: this.order.client.accountCode,
        phone: this.order.client.phone,
        email: this.order.client.email,
        contactMethod: this.order.client.contactMethod,
        contactCall: this.order.client.contactCall,
        contactText: this.order.client.contactText,
        contactEmail: this.order.client.contactEmail
      }
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({
      getOrder: 'order/getOrder'
    }),
    fullNameErrors () {
      const errors = []

      if (!this.$v.currentItem.fullName.$dirty) return errors

      if (!this.$v.currentItem.fullName.required) {
        errors.push('Name is required')
      }
      return errors
    },
    titleErrors () {
      const errors = []

      if (!this.$v.currentItem.title.$dirty) return errors

      if (!this.$v.currentItem.title.required) {
        errors.push('Title is required')
      }
      return errors
    },
    organizationErrors () {
      const errors = []

      if (!this.$v.currentItem.organization.$dirty) return errors

      if (!this.$v.currentItem.organization.required) {
        errors.push('Organization is required')
      }
      return errors
    },
    phoneErrors () {
      const errors = []

      if (!this.$v.currentItem.phone.$dirty) return errors

      if (!this.$v.currentItem.phone.required) {
        errors.push('Phone is required')
      }
      return errors
    },
    emailErrors () {
      const errors = []

      if (!this.$v.currentItem.email.$dirty) return errors

      if (!this.$v.currentItem.email.required) {
        errors.push('Email is required')
      }
      if (!this.$v.currentItem.email.email) {
        errors.push('Email address is not valid')
      }
      return errors
    },
    contactMethodErrors () {
      const errors = []
      if (
        !this.$v.currentItem.contactCall.$dirty &&
        !this.$v.currentItem.contactText.$dirty &&
        !this.$v.currentItem.contactEmail.$dirty
      ) {
        return errors
      }

      if (
        !this.currentItem.contactCall &&
        !this.currentItem.contactText &&
        !this.currentItem.contactEmail
      ) {
        errors.push('Contact Method is required')
      }
      return errors
    }
  },
  mixins: [validationMixin],
  validations: {
    currentItem: {
      fullName: {
        required
      },
      title: {
        required
      },
      organization: {
        required
      },
      phone: {
        required
      },
      email: {
        email,
        required
      },
      contactCall: {
        required: false
      },
      contactText: {
        required: false
      },
      contactEmail: {
        required: false
      }
    }
  },
  methods: {
    ...mapActions('order', ['setOrder']),
    onSave () {
      this.project.client = { ...this.currentItem }

      this.$emit('onChange', {
        project: this.project
      })
      this.currentSection = 'add'
      this.$emit('onChangeSection', {
        section: 'finish'
      })
    },
    formatPhone () {
      this.$v.currentItem.phone.$touch()
      var x = this.currentItem.phone
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.currentItem.phone = !x[2]
        ? x[1]
        : x[1] + '.' + x[2] + (x[3] ? '.' + x[3] : '')
      // this.currentItem.phone = this.currentItem.phone.replace(/D/g, '')
      const isValid =
        this.$v.currentItem.fullName.required &&
        this.$v.currentItem.title.required &&
        this.$v.currentItem.organization.required &&
        this.$v.currentItem.phone.required &&
        this.$v.currentItem.email.required &&
        (this.currentItem.contactCall ||
          this.currentItem.contactText ||
          this.currentItem.contactEmail)

      this.project.client = { ...this.currentItem }
      this.$emit('onChange', {
        project: this.project,
        isDisabled: !isValid
      })
    },
    onChange (e, field) {
      if (this.$v.currentItem[field]) {
        this.$v.currentItem[field].$touch()
      }

      const isValid =
        this.$v.currentItem.fullName.required &&
        this.$v.currentItem.title.required &&
        this.$v.currentItem.organization.required &&
        this.$v.currentItem.phone.required &&
        this.$v.currentItem.email.required &&
        (this.currentItem.contactCall ||
          this.currentItem.contactText ||
          this.currentItem.contactEmail)

      this.project.client = { ...this.currentItem }
      this.$emit('onChange', {
        project: this.project,
        isDisabled: !isValid
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
