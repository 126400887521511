<template>
  <v-container fluid class="pb-8" id="page">
    <AboutClient
      v-if="currentSection === 'start' && getOrder.orderType"
      :order="getOrder"
      @onChange="onChange($event)"
    />
    <!-- <AboutClientContact
      v-if="currentSection === 'start'"
      :order="getOrder"
      @onChange="onChange($event)"
    /> -->
    <AboutClientAccount
      v-if="currentSection === 'finish' && getOrder.orderType"
      :order="getOrder"
      @onChange="onChange($event)"
      @onChangeSection="changeSection($event)"
    />
    <v-row
      v-if="currentSection === 'start'"
      class="pt-4"
      align="center"
      justify="center"
    >
      <v-col cols="6" class="d-flex justify-start">
        <v-row>
          <v-col cols="6" class="d-flex justify-start">
            <v-btn right plain outlined @click="startOver"> Start Over </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn class="mr-4" left plain outlined @click="nextStep(3)">
              Back
            </v-btn>
            <v-btn
              right
              plain
              outlined
              :disabled="isDisabled"
              @click="currentSection = 'finish'"
            >
              Next
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <v-row
      v-if="currentSection === 'contact'"
      class="pt-4"
      align="center"
      justify="center"
    >
      <v-col cols="6" class="d-flex justify-end">
        <v-btn
          class="mr-4"
          left
          plain
          outlined
          @click="currentSection = 'start'"
        >
          Back
        </v-btn>
        <v-btn right plain outlined @click="currentSection = 'finish'">
          Next
        </v-btn>
      </v-col>
    </v-row> -->
    <v-row
      v-if="currentSection === 'finish'"
      class="pt-4"
      align="center"
      justify="center"
    >
      <v-col cols="6" class="d-flex justify-start">
        <v-row>
          <v-col cols="6" class="d-flex justify-start">
            <v-btn right plain outlined @click="startOver"> Start Over </v-btn>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn
              class="mr-4"
              left
              plain
              outlined
              @click="currentSection = 'start'"
            >
              Back
            </v-btn>
            <v-btn
              right
              plain
              outlined
              :disabled="isDisabled"
              @click="nextStep(5)"
            >
              Next
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AboutClient from '@/components/AboutClient'
import AboutClientAccount from '@/components/AboutClientAccount'
// import AboutClientContact from '@/components/AboutClientContact'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'info',
  title: 'Info',
  components: {
    AboutClient,
    AboutClientAccount
    // AboutClientContact
  },
  props: {},
  data: function () {
    return {
      currentSection: 'start',
      order: { ...this.getOrder },
      isDisabled: true
    }
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({
      getOrder: 'order/getOrder'
    })
  },
  async created () {
    this.checkParams()
  },
  methods: {
    startOver () {
      this.$emit('startOver')
    },
    onChange (event) {
      this.isDisabled = event.isDisabled
      this.$emit('onChangeProjectAbout', {
        order: event.project
      })
    },
    changeSection (event) {
      this.currentSection = event.section
    },
    nextStep (n) {
      this.$emit('nextStep', {
        n: n
      })
    },
    async checkParams () {
      this.order = { ...this.getOrder }
      this.isDisabled = !(
        this.order.client.fullName &&
        this.order.client.title &&
        this.order.client.organization &&
        this.order.client.phone &&
        this.order.client.email &&
        (this.order.client.contactCall ||
          this.order.client.contactText ||
          this.order.client.contactEmail)
      )
    }
  }
}
</script>
